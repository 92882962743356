import { mapState, mapGetters, mapActions } from 'vuex'
import { MODULE, LOGIN, RESET_PASSWORD, UPDATE_PASSWORD, LOGOUT, REFRESH_TOKEN } from '@/state/modules/auth'
import { USER_MODULE, GET_ALL_USERS, UPDATE_USER_STATUS, GET_USER, GET_PROFILE, UPDATE_USER, UPDATE_PROFILE, UNLOCK_USER, CREATE_USER } from '@/state/modules/user'
import { LOOKUP_MODULE, LOOKUP_ROLE, LOOKUP_PERMISSION, LOOKUP_MEETING_ROOM} from '@/state/modules/lookup'
import { ROOM_MODULE, GET_ALL_ROOMS, } from '@/state/modules/meetingRoom'
import { MEETING_ROOM_BOOKING_MODULE, GET_ALL_BOOKINGS, GET_BOOKING, UPDATE_BOOKING, CREATE_BOOKING, DELETE_BOOKING } from '@/state/modules/meetingRoomBooking'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue'])

export const authMethods = mapActions(MODULE, [LOGIN, RESET_PASSWORD, UPDATE_PASSWORD, LOGOUT, REFRESH_TOKEN])
export const userMethods = mapActions(USER_MODULE, [GET_ALL_USERS, UPDATE_USER_STATUS, GET_USER, GET_PROFILE, UPDATE_USER, UPDATE_PROFILE, UNLOCK_USER, CREATE_USER])
export const lookupMethods = mapActions(LOOKUP_MODULE, [LOOKUP_ROLE, LOOKUP_PERMISSION, LOOKUP_MEETING_ROOM])
export const meetingRoomMethods = mapActions(ROOM_MODULE, [GET_ALL_ROOMS])
export const meetingRoomBookingMethods = mapActions(MEETING_ROOM_BOOKING_MODULE, [GET_ALL_BOOKINGS, GET_BOOKING, UPDATE_BOOKING, CREATE_BOOKING, DELETE_BOOKING])