<script>
import { authMethods } from "@/state/helpers";
import { required, email } from "vuelidate/lib/validators";
import { MEDIUM } from '@/constants/enums';
import { mapState } from "vuex";
import { MODULE } from '@/state/modules/auth'

export default {
  data() {
    return {
      submitted: false,
      resetForm: {
        email: '',
        medium: MEDIUM.EMAIL,
      },
    };
  },
  validations: {
    resetForm: {
      email: { required, email }
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  computed: {
    ...mapState(MODULE, ['status']),
  },
  methods: {
    ...authMethods,
    requestResetPassword() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.resetPassword({ data: this.resetForm });
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/logo_hextar_correct.png" style="height: 30%; width: 30%;" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{ $t('label.resetPassword') }}</h4>
                        <p class="text-muted">{{ $t('label.resetPasswordToProject', [$t('projectName')]) }}</p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="requestResetPassword">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">{{ $t('label.email') }}</label>
                            <input v-model="resetForm.email" type="email" class="form-control" id="useremail"
                              :placeholder="$t('label.enter', [$t('label.password')])"
                              :class="{ 'is-invalid': submitted && $v.resetForm.email.$error }" />
                            <div v-if="submitted && $v.resetForm.email.$error" class="invalid-feedback">
                              <span v-if="!$v.resetForm.email.required">{{ $t('message.requiredX', [$t('label.email')])
                              }}</span>
                              <span v-if="!$v.resetForm.email.email">{{ $t('message.validX', [$t('label.email')])
                              }}</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit"
                              :disabled="status.processiong">{{ $t('label.reset') }}</button>
                          </div>
                          <div class="mt-4 text-center">
                            <router-link tag="a" to="/login" class="text-muted">
                              {{ $t('label.signInToContinueToProject') }}
                            </router-link>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © {{ new Date().getFullYear() }} {{ $t('projectName') }}.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>